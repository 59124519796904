jQuery(document).ready(function($) {


	/* скролл до блока в href при клике на ссылку*/
	$(document).on('click', '.scrollToBlock', function() {
		$("html, body").animate({ scrollTop: ($($(this).attr('href')).offset().top - ($(window).height() / 3)) }, "slow");
		$.fancybox.close();
		return false;
	});

	if ($("#contactsForm").length > 0) {
		$("#contactsForm").validate({
			submitHandler: function(form) {
				sending($(form));
			},
			rules: {
				name: {
					required: true
				},
				phone: {
					required: true
				},
				email: {
					email: true
				},
				agreement: {
					required: true
				}
			}
		});
	}
	collapceNav();
	$(document).on('click', '.js-nav-icon', function(event) {
		event.preventDefault();
	});
	watcherCollapse();
	$(document).on('click', 'body', function(event) {
		if($(window).width() < 768){
			if($(event.target).parents('.js-header').length === 0){
				$('#collapseMenu').collapse('hide');
			}
		}
	});

	$('.fog-effect').on('click', function () {
		$('.fog-effect').removeClass('fog-effect');
	});

	featuresSlider();
	productGallerySlider();
	startNumAnimate(false);
	$(window).scroll(function () { // Когда страница прокручивается
		startNumAnimate(true);
	});

	function startNumAnimate(isScroll) {
		if ($('.js-suppliers__number').length > 0) {
			$('.js-suppliers__number').each(function () {
				var action = false;
				if (isScroll) {
					var scrTop = $(window).scrollTop();
					action = scrTop > $(this).offset().top - $(window).height();
				} else {
					action = $(this).offset().top < $(window).height();
				}
				if (!$(this).hasClass('start') && action) {
					numAnimate($(this));
				}
			});
		}
	}

	function numAnimate (item){
		if(item){
			item.addClass("start");
			var result = item.text();
			var time= 200;
			var interval = Math.floor(parseInt(result) / time);
			var number = result-(time*interval);
			setInterval(function () {
				number = number+interval;
				if (number<=result) {
					item.text(number);
				}
			}, 10);
		}
	};
});

$(window).resize(function (event) {
  // resizeProductSlider();
	collapceNav();
});
function collapceNav(){
	let collapseMenu = $('#collapseMenu');
	if($(window).width() < 768){
		collapseMenu.collapse('hide');
		$('[id^="collapseNavItem"]').each(function(index, el) {
			$(el).collapse('hide');
		});
	}
	else{
		if(!collapseMenu.hasClass('show')){
			collapseMenu.collapse('show');
		}

	}
}
function watcherCollapse(element) {
  element = element ? element : ".js-collapse";
  $(document).on("show.bs.collapse", element, function showBsCollapse(e) {
    $(e.currentTarget).find('[data-target="#' + $(e.target).attr('id') + '"] i.i_plus, [href="#' + $(e.target).attr('id') + '"] i.i_plus').first().removeClass("i_plus").addClass("i_minus");
  });
  $(document).on("hide.bs.collapse", element, function showBsCollapse(e) {
    $(e.currentTarget).find('[data-target="#' + $(e.target).attr('id') + '"] i.i_minus,[href="#' + $(e.target).attr('id') + '"] i.i_minus').first().removeClass("i_minus").addClass("i_plus");
  });
}

function sending(form) {
	var term = form.serialize();
	form.find('button[type="submit"]').addClass('disabled');
	form.addClass('form-disabled');
	var posting = $.post('assets/templates/ajax/', { action: 'modalForm', data: term });

	posting.done(function(data) {
			$('.pm').remove();
			var data = $.parseJSON(data);
			form.find('button[type="submit"]').removeClass('disabled');
			form.removeClass('form-disabled');
			if (data.result === true) {
				if (form.find('.js-message').length > 0) {
					form.find('.js-message').append($('<p class="pm alert alert-success">' + data.errorCode + '</p>').hide().fadeIn(500));
				} else {
					form.find('button[type="submit"]').before($('<p class="pm alert alert-success">' + data.errorCode + '</p>').hide().fadeIn(500));
				}
				form[0].reset();
				setTimeout(function() {
					$.fancybox.close();
					$('.pm').remove();
				}, 3000);
			} else {
				grecaptcha.reset(widgetId1);
				grecaptcha.reset(widgetId2);
				if (form.find('.js-message').length > 0) {
					form.find('.js-message').append($('<p class="pm alert alert-danger">' + data.errorCode + '</p>').hide().fadeIn(500));
				} else {
					form.find('button[type="submit"]').before($('<p class="pm alert alert-danger">' + data.errorCode + '</p>').hide().fadeIn(500));
				}
			}
		})
		.fail(function() {
			$('.pm').remove();
			form.find('button[type="submit"]').removeClass('disabled');
			form.removeClass('form-disabled');
			var errorText = "Произошла ошибка";
			grecaptcha.reset(widgetId1);
			grecaptcha.reset(widgetId2);
			if (form.find('.js-message').length > 0) {
				form.find('.js-message').append($('<p class="pm alert alert-danger">' + errorText + '</p>').hide().fadeIn(500));
			} else {
				form.find('button[type="submit"]').before($('<p class="pm alert alert-danger">' + errorText + '</p>').hide().fadeIn(500));
			}
		});
}

function featuresSlider() {
	var slider = $('.js-featuresSlider');
	if (slider.length > 0) {
		slider.slick({
			infinite: true,
			slidesToShow: 4,
			slidesToScroll: 2,
			arrows: false,
			dots: false,
			responsive: [{
				breakpoint: 991,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
                    arrows: true
				}
			}, {
				breakpoint: 575,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
                    arrows: true
				}
			}]

		});

	}
}

function productGallerySlider() {
  var sliderFor = '.slider-for',
    sliderNav = '.slider-nav';
  if ($(sliderFor).length > 0) {
    $(sliderFor).slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      prevArrow: '<div class="slider-arrow slider-arrow_left"><i class="i i_arrow-left slider-arrow__icon"></i></div>',
      nextArrow: '<div class="slider-arrow slider-arrow_right"><i class="i i_arrow-left slider-arrow__icon"></i></div>',
      fade: true,
      asNavFor: sliderNav,
      responsive: [{
        breakpoint: 992,
        settings: {
          arrows: true
        }
      }, {
        breakpoint: 575,
        settings: {
          arrows: true
        }
      }]
    });
    $(sliderNav).slick({
      slidesToShow: 5,
      slidesToScroll: 1,
      asNavFor: sliderFor,
      arrows: false,
      centerMode: false,
      focusOnSelect: true,
      prevArrow: '<div class="js-nav-arrow slider-arrow slider-arrow_left"><i class="i i_arrow-left slider-arrow__icon"></i></div>',
      nextArrow: '<div class="js-nav-arrow slider-arrow slider-arrow_right"><i class="i i_arrow-left slider-arrow__icon"></i></div>',
      responsive: [{
        breakpoint: 992,
        settings: {
          slidesToShow: 5,
          vertical: false
        }
      }, {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          vertical: false
        }
      }, {
        breakpoint: 425,
        settings: {
          slidesToShow: 3,
          vertical: false
        }
      }]
    });

    $(sliderFor).on('setPosition', function (event, slick, direction) {
      // resizeProductSlider();
    });

  }
}
function resizeProductSlider() {
  /*var margin = 5;*/
  $('.slider-nav').slick('setPosition');
  var height = $('.slider-nav').height();

  /*var arrowsH = $('.js-nav-arrow').height();*/
  if (height) {
    if ($(window).width() < 991.98 && $(window).width() > 767) {
      $('.slider-for__image').height(height);
    } else {
      $('.slider-for__image').css("height", "");
      $('.slider-nav').slick('setPosition');
    }
  } else {
    $('.slider-for__image').css("height", "");
    $('.slider-nav').slick('setPosition');
  }
}
